<template>
  <div class="calendar-events">
    <b-container class="mt-5">
      <b-row class="mb-4" align-v="center">
        <b-col cols="12">
          <h1 class="display-4  super-strong">Vinculación : Eventos</h1>
          <hr class="line" />
        </b-col>
      </b-row>

      <b-row class="mb-5">
        <b-col md="12">
          <h2 class="">
            <strong>{{ event.tituloEvento }}</strong>
          </h2>
        </b-col>
        <b-col md="8">
          <b-card-group deck>
            <b-card :header="`Título del evento: ${event.tituloEvento}`">
              <b-list-group>
                <b-list-group-item>
                  Sede:
                  <strong>{{ event.answer.sede }}</strong>
                </b-list-group-item>
                <b-list-group-item>
                  Ubicación:
                  <strong>{{ event.answer.direccion_completa }}</strong>
                </b-list-group-item>
                <b-list-group-item>
                  Modalidad:
                  <strong>{{ event.answer.modalidad }}</strong>
                </b-list-group-item>
                <b-list-group-item v-if="event.answer.plataforma">
                  Plataforma:
                  <strong>
                    <a :href="event.answer.plataforma" target="_blank">
                      {{ event.answer.plataforma }}
                    </a>
                  </strong>
                </b-list-group-item>
                <b-list-group-item v-if="event.answer.fechaEvento">
                  Fecha y hora del evento:
                  <strong>
                    {{ event.answer.fechaEvento }}
                    {{ event.answer.horario_evento }}
                  </strong>
                </b-list-group-item>

                <b-list-group-item>
                  <p class="card-text mt-2">
                    <small>Detalles:</small><br />
                    <strong>{{ event.answer.descripcion }}</strong>
                  </p>
                </b-list-group-item>

                <b-list-group-item>
                  <small>Contacto</small><br />
                  <span v-if="event.answer.nombreCompleto">
                    Organizador:
                    <strong>{{ event.answer.nombreCompleto }}</strong>
                  </span>
                  <br />
                  <span v-if="event.answer.email">
                    Email: <strong>{{ event.answer.email }}</strong>
                  </span>
                  <br />
                  <span v-if="event.answer.telefono">
                    Tel: <strong>{{ event.answer.telefono }}</strong>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-card-group>
        </b-col>
        <b-col md="4" class="">
          <b-img
            v-for="(picture, i) in event.answer.cartel_evento"
            :src="picture.content"
            fluid
            :alt="event.tituloEvento"
            class="shadow-3"
          ></b-img>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import wsExternal from "@/services/external";

export default {
  name: "EventoShow",
  components: {},

  props: {
    cfgTitle: String,
  },

  data() {
    return {
      event: {
        answer: {
          cartel_evento: {},
        },
      },
      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
    };
  },

  mounted() {
    document.title = "Agenda | Instituto de los Mexicanos en el Exterior";
    this.loadEvent(this.$route.params.uuid);
    //this.$route
  },

  methods: {
    loadEvent(uuid) {
      wsExternal.getEvent(uuid).then(
        (response) => {
          this.event = response.data.data[0];
        },
        (error) => {
          this.event =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>
